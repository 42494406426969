import mixpanel from 'mixpanel-browser';
import moment from "moment";

const MIXPANEL_TOKEN = '7170576f0790693811e5f8ebe2464a26';

const isPreventTrack = (process.env.NODE_ENV === 'development');

class Mixpanel {
    lastPathname = undefined;
    lastPageName = undefined;
    lastTrackTime = undefined;

    constructor() {
        this.init();
    }

    init = () => {
        if (isPreventTrack) return;

        mixpanel.init(MIXPANEL_TOKEN, {debug: process.env.NODE_ENV === 'development'});
    };

    // Track is send data to Mixpanel, if previous has time_event on same name then it include duration data
    // name: pathname
    // pageName: title of page
    track = ({name, pageName, options = {}}) => {
        if (isPreventTrack) return;

        const systemOptions = {
            'LS Local Time': moment().format('llll'),
            'LS Local Time Zone': moment().format('Z'),
            'LS Duration Minutes': this.lastTrackTime ? moment().diff(this.lastTrackTime, 'minutes', true) : 0,
            'LS Duration of Page': this.lastPageName || pageName, // pageName only first after refresh browser
            'LS Page': pageName,
        };

        mixpanel.track(name, {...systemOptions, ...options});

        this.lastTrackTime = moment();
        this.lastPageName = pageName;
    };

    // Track page is called when go to a new page and webapp initial
    // trackNewPage = ({pathname, pageName, options = {}}) => {
    //     if (isPreventTrack) return;
    //     if (!pathname) return;
    //
    //     // Do when go to a new page
    //     this.track({name: pathname, pageName, ...options});
    // }
}

export const baTracker = new Mixpanel();
